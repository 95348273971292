import {Subtitles} from "../../compenents/Commons";
import {Button} from "../../compenents/Commons/button";
import './styles.css'
import {IMG_URL} from "../../utils/api/defaultApi";

const Origin = () => {
    return (
            <div className="origin">
                <div>
                    <Subtitles imageURL={IMG_URL+"cercle-jaune-338x338.webp"} value="À l’origine, Nutriset" />
                    <p>
                        En 1986, Michel Lescanne crée <strong>Nutriset</strong> sur un constat : des millions d’enfants meurent chaque
                        année de malnutrition dans les pays pauvres, et la communauté internationale demeure incapable
                        de les soigner, faute de traitements adaptés.
                    </p>
                    <p>
                        <strong>Le groupe Nutriset</strong> se spécialise dans la recherche, le développement et la production d’aliments
                        à haute valeur nutritionnelle, destinés à traiter et prévenir les différentes formes de
                        malnutrition dans le monde. Le groupe développe des systèmes de production pérennes et
                        responsables au plus proche des besoins.
                    </p>
                    <Button type="button" url={process.env.REACT_APP_APP_URL+"#project"} value={'Voir les projets soutenus'} />
                </div>
                <figure className="origin__video">
                    <img src="https://via.placeholder.com/150.png" alt="fond de dotatino" />
                    <figcaption>Titre de la video</figcaption>
                </figure>
            </div>
    );
}

const DomaineComplementaire = () =>  {
    return (
        <div className="about__grid">
            <p className="element__jaune"><img src={IMG_URL+"element-jaune-01.webp"} width="203.6px" alt="fond de dotation"  /></p>
            <div>
                <Subtitles value="Explorer des domaines complémentaires" imageURL={IMG_URL+"cercle-jaune-338x338.webp"} />
                <p>
                    L’activité du Groupe s’est toujours appuyée sur des expertises et des collaborations
                    scientifiques variées, utilisant ses ressources et compétences propres ou en association avec
                    des partenaires de recherche externes (nutrition, sciences sociales, science des aliments).
                </p>
                <p>
                    En février 2021, afin d’explorer des domaines complémentaires à son mandat initial, <b>le Groupe
                    Nutriset a donné une nouvelle dimension à son action avec la création d’une structure d’intérêt
                    général : le Fonds de Dotation Groupe Nutriset.</b>
                </p>
            </div>
        </div>
    );
}

const GeneseElement = ({el}) => {
    let textList = el.text.split("- ");
    return (
        <>
            <hr style={{color: "white", height:"2px"}} />
            <div className="about__geneses__title">
                <p style={{width: "auto", margin: "0"}}><img src={IMG_URL+"puce-genese.svg"}  alt="puce-genese" width="15px" /></p>
                <p className="geneses__date">{el.title}</p>
            </div>
            <hr style={{color: "white", height:"2px"}} />
            {textList.map((element, key) => key !== 0 ? <p className="geneses__element">- {element}</p> : null)}
        </>
    );
}

const GeneseSquare = () => {
    return (
        <div className="genese__square">
            <h3>Genèse du Fonds de dotation Groupe Nutriset</h3>
            <p>Évolution croisée de l’humanitaire, de la recherche et du Groupe Nutriset depuis sa création.</p>
            <>
                <hr style={{color: "white", height:"2px"}} />
                <div className="about__geneses__title">
                    <p style={{width: "auto", margin: "0"}}><img src={IMG_URL+"puce-genese.svg"}  alt="puce-genese" width="15px" /></p>
                    <p className="geneses__date">Les années 1980</p>
                </div>
                <hr style={{color: "white", height:"2px"}} />
                <p className="geneses__element">- La faim dans le monde est un thème récurrent dans l’opinion publique. Des formules de lait enrichies sont testées sur des crises humanitaires.</p>
                <p>- Le 1er mai 1986, Michel Lescanne fonde <a href="https://www.nutriset.fr/" target="_blank" rel="noreferrer" className="nutriset__link">Nutriset</a>.</p>

                <GeneseElement
                    el={{
                        title: "Les années 1990",
                        text: "- En 1992, la FAO et l’OMS convoquent la première Conférence Internationale sur la Nutrition.\n" +
                            "- En 1994, le génocide rwandais pose la question du traitement efficace et à grande échelle des enfants victimes de malnutrition aigüe sévère.\n" +
                            "- Nutriset propose Plumpy’Nut®, un aliment thérapeutique prêt à l’emploi facilement conservable et distribuable pour une utilisation communautaire.      "
                    }}
                />
                <GeneseElement
                    el={{
                        title: "Les années 2000",
                        text: "- Face à l’explosion des besoins, un continuum de l’aide s’impose de l’urgence au développement. La nutrition devient un thème d’intervention en soi. - De la notion de traitement, on passe à celle de prévention et Nutriset développe en collaboration avec l’université américaine UC Davis Nutributter."
                    }}
                />
                <GeneseElement
                    el={{
                        title: "Les années 2010",
                        text: " - Les ONG humanitaires deviennent des acteurs majeurs de la gestion des crises humanitaires. Sous l’égide de l’OMS qui établit les normes d’alimentation des femmes enceintes et allaitantes, des nourrissons et des enfants de 6 à 24 mois, la période des 1000 jours de la conception de l’enfant à ses deux ans focalise l’attention des nutritionnistes. Nutriset développe à cette fin Enov’Mum et Plumpy’Mum à l’attention des femmes enceintes et allaitantes."
                    }}
                />
                <GeneseElement
                    el={{
                        title: "Les années 2021",
                        text: "  - The Lancet publie l’article ‘Effective interventions to address maternal and child malnutrition : an update of the evidence’ et met en lumière l’impact positif des SQ-LNS. Cette recommandation est un gage de reconnaissance de l’action du Groupe Nutriset depuis 35 ans auprès des populations les plus vulnérables pour leur permettre d’être en bonne santé et de mieux vivre par la nutrition. - Le Fonds de Dotation Groupe Nutriset est créé en 2021 pour aller plus loin dans cette mission et soutenir des recherches et des actions d’intérêt général en faveur d’une meilleure santé globale et éducation de ces populations."
                    }}
                />
            </>
        </div>

    );
}

const AboutObject = () => {
    return (
        <div className="about__object">
            <div className="about__object__text">
                <Subtitles  imageURL={IMG_URL+"cercle-jaune-338x338.webp"} value="L’objet du Fonds de Dotation Groupe Nutriset" />
                <div>
                    <p>
                        Le Fonds de Dotation Groupe Nutriset met en œuvre directement, ou indirectement par le biais
                        d’autres organismes sans but lucratif d’intérêt général :
                    </p>
                    <p>
                        - Des actions en vue de <b>l’accès du plus grand nombre à un bon état de santé et à l’éducation,</b>
                    </p>
                    <p>
                        - Des activités de recherche en matière scientifique, en nutrition, sciences des aliments et
                        sciences humaines et toute autre thématique de <b>recherche en lien avec la santé et l’éducation.</b>
                    </p>
                    <p>
                        En vue de répondre notamment aux besoins fondamentaux des populations en matière d’éducation et
                        de santé, il organise <strong>des actions humanitaires</strong> ou finance des organismes éligibles au régime du
                        mécénat pour mener ces actions.
                    </p>
                </div>

            </div>
            <p style={{textAlign: "end"}}><img src={IMG_URL+'photo-dlp02.webp'} className="about__object__img" alt="diplo-2" /></p>
        </div>
    );
}

export const About = () => {
    return (
        <section className="about">
            <div className="container">
                <h1 className="about__title__h2" id="about">Qui est le Fonds de Dotation Groupe Nutriset ?</h1>
                <div className="about__title__hr" />
                <p className="introduction" >
                    En février 2021, <b>le Groupe Nutriset</b> a voulu donner une autre dimension à son action, par la création
                    d’un Fonds de dotation.
                </p>
                <Origin />
                <DomaineComplementaire />
            </div>
            <div>
                <div className="about__geneses">
                    <p><img src={IMG_URL+'photo-dlp01.webp'} alt="diplo01" /></p>
                    <GeneseSquare />
                </div>
               <AboutObject />
            </div>
            <div className="container">
                <div className="about__tasks">
                    <p className="element__jaune"><img src={IMG_URL+"element-jaune-01.webp"} alt="element-jaune" /></p>
                    <div>
                        <Subtitles imageURL={IMG_URL+"cercle-jaune-338x338.webp"} value="Les activités du Fonds de Dotation Groupe Nutriset" />
                        <p>
                            De manière directe ou par le biais de partenaires, ses missions consistent à soutenir et
                            organiser des actions dans les domaines de la recherche, l’éducation et l’humanitaire :
                        </p>
                        <p>
                            <b>Organiser des actions de recherche et de formation</b> (soutien d’études de terrain, de thèses,
                            publications scientifiques), des rencontres, des ateliers, des colloques et des séminaires ;
                        </p>
                        <p>
                            <b>Mettre en place des partenariats</b> avec des associations et fondations dans le domaine de la santé
                            et l’éducation.
                        </p>
                        <p>
                            <b>En 2021, le Fonds de Dotation Groupe Nutriset a identifié la cognition comme fil conducteur de
                                ses activités initiales.</b>
                        </p>
                        <p>
                            A l’issue de ce premier cycle, une autre thématique, dont les caractéristiques répondront à
                            l’objet initialement défini, pourra devenir un nouveau fil rouge.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
