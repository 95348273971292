import "./styles.css";

import {useState} from "react";

import {Subtitles, Title} from "../../compenents/Commons";
import {IMG_URL} from "../../utils/api/defaultApi";

const Processus = () => {
    return (
        <div className="cognition__process">
            <div className="cognition__text">
                <h3>
                    Cognition : Processus par lequel un organisme acquiert la conscience des événements et objets de
                    son environnement. (Le Robert)
                </h3>
                <p>
                    Le Fonds de Dotation Groupe Nutriset voit dans l’exploration du champ de la cognition et de ses
                    interactions avec l’alimentation et la nutrition, la possibilité de soutenir un vaste ensemble
                    pluridisciplinaire de recherches et d’actions. Sa vocation d’améliorer l’accès du plus grand nombre
                    à un bon état de santé, est un écho au mandat opérationnel des structures du groupe.
                </p>
            </div>
            <p className="cognition__image element__violet"><img src={IMG_URL + "element-violet-01.webp"} alt="element-violet" /></p>
        </div>
    );
}

const DeveloppementCapaciteCognitive = () => {
    return (
        <div className="cognition__capacity">
            <p className="cognition__image"><img src={IMG_URL + "photo-togo-01.webp"} alt="img-togo-01"/></p>
            <div className="cognition__text psm">
                <Subtitles
                    value="Développement des capacités cognitives"
                    imageURL={IMG_URL + "cercle-violet-348x426.webp"}
                />
                <div className="">
                    <p>
                        Les spécialistes de la petite enfance ont démontré que, <b>pour se développer harmonieusement</b> et
                        avoir la capacité d’<b>atteindre son plein potentiel</b>, un enfant a besoin de recevoir une
                        alimentation adaptée à ses besoins, et bénéficier d’affection, de protection et de stimulation.
                        (Ref)
                    </p>
                    <p>
                        Un enfant souffrant de malnutrition (dénutrition ou obésité), et peu stimulé sur le plan
                        cognitif et socio-émotionnel, voit son développement affecté, de manière irréversible. C’est le
                        cas de près de 43% des enfants de moins de cinq ans dénutris vivant dans les pays à revenu
                        faible ou intermédiaire, qui ne reçoivent pas la nutrition, la protection et la stimulation
                        dont ils ont besoin (Ref.)
                    </p>
                    <p>
                        <b>La stimulation cognitive, psychosociale et affective des enfants souffrant de malnutrition
                            doit être complémentaire à une prise en charge nutritionnelle</b> pour favoriser la guérison
                        et permettre de réduire les retards développementaux occasionnés.
                    </p>
                    <p className="capitalize" >
                        REFERence à préciser Cf étude USA et psychologue interviewée France Culture
                    </p>
                    <p><b>
                        Pour les années 2022 - 24 le Fonds de Dotation Groupe Nutriset soutient l’ONG française Ana-Nour
                        pour accompagner la neuroéducation en Afrique.
                    </b></p>
                </div>
            </div>
        </div>
    );
}

const MaintienCapaciteCognitive = () => {
    return (
        <div className="cognition__restauration psm">
            <div className="cognition__text">
                <Subtitles value="Maintien ou restauration des capacités cognitives"
                           imageURL={IMG_URL + "cercle-violet-348x426.webp"}/>
                <div>
                    <p>Un retard de croissance physique ou de développement cognitif peut être considéré à la fois comme
                        la conséquence d’une privation passée et comme un indicateur de pauvreté future.</p>
                    <p><b>La dénutrition perturbe le développement normal du cerveau</b>, engendrant des
                        dysfonctionnements étendus des fonctions cognitives, psychologiques et comportementales. Cela
                        peut se traduire par des résultats scolaires suboptimaux, une performance économique réduite à
                        l’âge adulte, ainsi que des troubles affectifs et sociaux.</p>
                    <p><b>Les dysfonctionnements des fonctions exécutives</b> (capacité de concentration sur un
                        objectif, mémoire de travail, régulation effective des émotions), aboutissent parfois à un
                        déclin cognitif important chez les seniors (ex. cas de la maladie d’Alzheimer) et pourraient
                        être également à l’origine des défauts de performance sociale ou économique des survivants de la
                        malnutrition aiguë sévère.</p>
                    <p><b>Pour les années 2022 - 24 le Fonds de Dotation Groupe Nutriset soutient le projet Ex-Core
                        porté par le Professeur Terrence Forrester en Jamaïque.</b></p>
                </div>
            </div>
            <p className="cognition__image element__violet"><img src={IMG_URL + "element-violet-02.webp"} alt="element-violet-02" /></p>
        </div>
    );
}

const DetailsDiv = ({isShow, handleClick}) => {
    return (
        <>
            <a href="/" className="zoom__div__more" hidden={!isShow} onClick={(e)=>handleClick(e)}>
                <img src="/images/Bouton%20triangle%20vert.svg" className="rotate" alt="readmore" />
                <b>Cacher la suite</b>
            </a>
            <a href="/" className="zoom__div__more" hidden={isShow} onClick={(e)=>handleClick(e)}>
                <img src="/images/Bouton%20triangle%20vert.svg" alt="readmore" />
                <b>Lire la suite</b>
            </a>
            <div className="zoom__div__details" hidden={!isShow}>
                <div>
                    <p>Cette période, sensible pour le développement et la sécurisation de l’enfant, définit
                        les prémisses de la santé et du bien-être1 de l’individu tout au long de la vie. Il
                        s’agit de proposer des recommandations pour mieux accompagner les parents durant
                        cette phase déterminante pour leur enfant.</p>
                    <p>Jouer, chanter, raconter des histoires, explorer la nature, favoriser l’éveil
                        culturel et artistique, sont essentiels au développement de l’enfant. Ces activités
                        immatérielles et de sociabilisation soutiennent l’attention du bébé et l’aident à
                        repérer les contextes, les personnes et les récurrences utiles (comme celles du
                        langage ou des lois naturelles).</p>
                </div>
                <p>(Extrait du Rapport de la commission des 1000 premiers jours, commission d’expert
                    présidée par Boris Cyrulnik, Ministère des Solidarités et de la Santé, Septembre 2020)
                    1Définition de la santé au sens de l’OMS : « La santé est un état de complet bien-être
                    physique, mental et social et ne consiste pas seulement en une absence de maladie ou
                    d’infirmité. »</p>
            </div>
        </>
    );
}

export const Cognition = () => {
    const [isShow, setIsShow] = useState(false);

    function handleClick(e) {
        e.preventDefault();
        setIsShow(!isShow);
    }

    return (
        <div className="cognition">
            <div className="section__image">
                <img src={IMG_URL + "cercle-violet-348x426.webp"} alt="cercle violet coupé"/>
            </div>
            <div className="cognition__div__title">
                <Title id="cognition" value="La Cognition"/>
            </div>
            <Processus/>
            <DeveloppementCapaciteCognitive/>
            <MaintienCapaciteCognitive/>
            <div className="cognition__nutriset">
                <div>
                    <p className="cognition__image"><img src={IMG_URL + "photo-togo-02.webp"} alt="togo-02" /></p>
                    <h3 className="psm cognition__text" style={{fontWeight: "bold"}}>
                        Le Fonds de Dotation Groupe Nutriset voit dans l’exploration du champ de la cognition et de ses interactions avec la nutrition, la
                        possibilité de soutenir un vaste ensemble pluridisciplinaire de recherches et d’actions.
                    </h3>
                </div>
                <p className="element__violet3 element__violet">
                    <img src={IMG_URL + "element-violet-03.webp"} alt="element-violet-03" />
                </p>
            </div>
            <div className="zoom">
                <h1>ZOOM</h1>
                <div className="zoom__div">
                    <img src={IMG_URL + 'schema1000jours.webp'} alt="schema1000jours"/>
                    <h2>Les premiers 1000 jours</h2>
                    <p className="zoom__introduction">Les 1000 premiers jours de l’enfant – de sa conception à ses 2 ans – constituent un concept
                        incontournable pour de nombreux scientifiques, qui soulignent l’importance de cette période
                        clé pour tout individu.</p>
                    <DetailsDiv isShow={isShow} handleClick={handleClick} />
                </div>
            </div>
        </div>
    );
}