import './styles.css'
import {IMG_URL} from "../../utils/api/defaultApi";

export const Footer = () => {
  return (
      <footer>
          <div className="footer">
              <p><img src={IMG_URL+"logo-fdd-blanc.webp"} alt="fondDeDotationLogoBlanc" /></p>
              <div>
                  <p className="footer__address">10, Rue Saint-Maur <br /> Rouen 76000</p>
                  <p>Mentions légales</p>
              </div>
          </div>
      </footer>
  );
}