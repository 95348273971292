import './App.css';

import {Header} from "./sections/header";
import {About} from "./sections/about";
import {Cognition} from "./sections/cognition";
import {Project} from "./sections/project";
import {Partners} from "./sections/partner";
import {Contact} from "./sections/contact";
import {Footer} from "./sections/footer";
import {IMG_URL} from "./utils/api/defaultApi";


function App() {

    return (
            <div className="App">
                <Header/>
                <About/>
                <Cognition/>
                <Project/>
                <Partners/>
                <section className="section__blue">
                    <div className="container">
                        <h2>
                            Face à la multiplicité des causes et l’étendue des savoirs encore à acquérir en nutrition, le Groupe
                            Nutriset a souhaité mettre en place ce nouvel outil qu’est le fonds de dotation, pour permettre de
                            financer toujours plus de recherche, de multiplier les types de partenariats, générer et partager de
                            nouvelles connaissances.
                        </h2>
                    </div>
                </section>
                <Contact/>
                <Footer/>


            </div>
        );
}
export default App;
