import "./styles.css"
import {IMG_URL} from "../../../utils/api/defaultApi";

export const LandingImage = () => {

    return (
        <>
            <div className="landing">
                <div>
                    <h1>
                        Améliorer l’accès à la santé et à l’éducation pour tous
                    </h1>
                    <p>
                        Face à la multiplicité des causes et l’étendue des savoirs encore à acquérir en nutrition, le
                        Groupe
                        Nutriset a souhaité mettre en place ce nouvel outil qu’est le fonds de dotation, pour permettre
                        de
                        financer toujours plus de recherche, de multiplier les types de partenariats, générer et
                        partager de
                        nouvelles connaissances.
                    </p>
                    <hr />
                    <a href="#contact">Devenir partenaire</a>
                </div>
            </div>
            <div className="cercle__yellow">
                <img src={IMG_URL + 'cercle-jaune-338x338.webp'} alt="cercle-jaune"/>
            </div>
            <p className="landing__paragraph_sm">
                Face à la multiplicité des causes et l’étendue des savoirs encore à acquérir en nutrition, le Groupe
                Nutriset a souhaité mettre en place ce nouvel outil qu’est le fonds de dotation, pour permettre de
                financer toujours plus de recherche, de multiplier les types de partenariats, générer et partager de
                nouvelles connaissances.
            </p>
        </>
    );
}
