import "./styles.css";

import {Title} from "../../compenents/Commons";

import {IMG_URL} from "../../utils/api/defaultApi";

const PartnerSection = ({title, img}) => {
    return (
        <>
            <div className="partner__div__section">
                <h5 className="fw-bold mb-2">{title}</h5>
                <div>
                    {img.map((element, index) => <p key={index} className="partner__logo margin--none"><img src={element.url} alt={element.alt} /></p>)}
                </div>
            </div>
            <hr />
        </>
    );
}

export const Partners = () => {
     return (
         <section>
             <div className="partner__circle">
                 <img src={IMG_URL+"cercle-bleu-coupe.webp"} alt='cercle-bleu'/>
             </div>
             <div className="partner__title__div">
                 <Title id="partner"  value="Nos partenaires et réseaux" />
             </div>
             <p className="container partner__intro">
                 Ils soutiennent notre initiative et nous inspirent. <br />
                 Et si c’était vous demain ?
             </p>
             <div className="partner__div">
                 <div className="partner__div__section">
                     <h5 className="fw-bold mb-2">Nos partenaires de recherche</h5>
                     <div>
                        <p className="bg--white partner__logo margin--none">University of West indies, Jamaïque</p>
                        <p className="partner__logo margin--none"><img src={IMG_URL+"logo_isthia.webp"} alt="isthia" /></p>
                        <p className="bg--white partner__logo h71px margin--auto">IANEL</p>
                     </div>
                 </div>
                 <hr />
                 <PartnerSection
                     title="Fondations & OMG"
                     img={[
                         {url: IMG_URL+"logo-CFF.webp", alt: "CFF"},
                         {url: IMG_URL+"logo-fondation-ipsen.webp", alt: "ipsen"},
                         {url: IMG_URL+"logo-ana-nour.webp", alt: "anar-hour"}
                     ]}
                 />
                 <PartnerSection
                     title="Mécènes et sponsors"
                     img={[
                         {url: IMG_URL+"logo-groupe-nutriset.webp", alt: "nutriset"},
                         {url: IMG_URL+"logo-zz.webp", alt: "zz"},
                         {url: IMG_URL+"logo-dlp.webp", alt: "dlp"}
                     ]}
                 />
             </div>
         </section>
     );
}
