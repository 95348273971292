import styles from "./styles";
import "./styles.css"
import {Title} from "../../compenents/Commons";
import {Button} from "../../compenents/Commons/button";
import {IMG_URL} from "../../utils/api/defaultApi";
import {useState} from "react";

const ContactForm = () => {
    const [data, setData] = useState({
        name: "",
        phoneNumber: "",
        Organization: "",
        email: "",
        message: ""
    });


    function handleChange(e) {
        setData({...data, [e.target.name]: e.target.value})
    }

    function handleSubmit(e) {
        e.preventDefault()
        console.log(data);
    }

    return (
            <div className="mx-auto topmargin" style={{maxWidth: "850px"}}>
                <form className="row mb-0 text-center" onSubmit={handleSubmit} >
                    <div className="col-md-6 mb-4 form-group ">
                        <input
                            type="text"
                            id="name-form-input"
                            style={styles.input}
                            className="form-control"
                            placeholder="Nom"
                            defaultValue={data.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-md-6 mb-4 form-group">
                        <input
                            type="text"
                            id="organism-form-input"
                            style={styles.input}
                            className="form-control"
                            placeholder="Organisation"
                            onChange={handleChange}
                            defaultValue={data.Organization}
                        />
                    </div>
                    <div className="col-md-6 mb-4 form-group">
                        <input
                            type="text"
                            id="phone-form-input"
                            style={styles.input}
                            className="form-control"
                            placeholder="Téléphone"
                            onChange={handleChange}
                            defaultValue={data.phoneNumber}
                        />
                    </div>
                    <div className="col-md-6 mb-4 form-group">
                        <input
                            type="text"
                            id="email-form-input"
                            style={styles.input}
                            className="form-control"
                            placeholder="Adresse email"
                            onChange={handleChange}
                            defaultValue={data.email}
                        />
                    </div>
                    <div className="col-12 mb-4 form-group">
                        <textarea
                            id="message-form"
                            style={styles.input}
                            className="form-control"
                            rows="7"
                            cols="30"
                            placeholder="Message"
                            defaultValue={handleChange}
                        ></textarea>
                    </div>
                    <div className="col-12" style={{textAlign: "right"}}>
                        <Button type="submit" url={process.env.REACT_APP_APP_URL} value='Envoyer' />
                    </div>
                </form>
            </div>
    );
}

const ListSection = () => {
    return (
        <div className="row">
            <div className="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <p className="list__sub list__sub__title">
                    <center><img src={IMG_URL+"picto-FDD-mains.webp"} alt="picto-main" width="80px" height="67px" /></center> <br />
                    <b>Participer à des projets d’intérêt général</b>
                </p>
                <div>
                    <b className="list__sub">Vous souhaitez faire avancer la recherche ?</b>
                    <p>Améliorons ensemble les connaissances académiques et scientifiques autour de la cognition et de ses interactions avec l’alimentation et la nutrition.</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <p className="list__sub list__sub__title">
                    <center><img src={IMG_URL+"picto-FDD-livre.webp"} alt="picto-livre" width="80px" height="67px" /></center> <br />
                    <b>Faire valoir des valeurs humaines de partage et d’entraide</b>
                </p>
                <div>
                    <b className="list__sub">Vous souhaitez nous soumettre un projet ?</b>
                    <p>Agissez concrètement et au plus proche des besoins en nous proposant vos projets opérationnels, dont la mission d’intérêt général a vocation à favoriser l’accès à une meilleure santé et à l’éducation.
                        Des appels à projet seront publiés sur ce site.</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <p className="list__sub list__sub__title">
                    <center><img src={IMG_URL+"picto-FDD-don.webp"} alt="picto-don" width="80px" height="67px" /></center> <br />
                    <b>Soutenir la santé et l’éducation pour tous</b>
                </p>
                <div>
                    <b className="list__sub">Vous souhaitez participer financièrement à nos activités ?</b>
                    <p>Aidez-nous à rassembler des fonds au profit de la recherche et des actions de terrain promouvant une meilleure santé et meilleure éducation pour tous.</p>
                </div>
            </div>
        </div>
    );
}

export const Contact = () => {

    console.log(window.innerWidth);

    return (
        <section className="contact">
            <p className="contact__cerle__div">
                {window.innerWidth >= 1024 ? <img src={IMG_URL+"cercle-rose-coupe.webp"} alt="cercle rose coupé" /> : null}
                {window.innerWidth < 1024 ? <img src={IMG_URL+"cercle-rose-443x443.webp"} alt="cercle rose" /> : null }
            </p>
            <div className="contact__title__div">
                <Title id="contact" value="Nous contacter" />
            </div>
            <div className="container">
                <h4 className="text-center m-5 mt-0">
                    Vous impliquer aux côtés du Fonds de Dotation <br />
                    Groupe Nutriset, c’est :
                </h4>
                <ListSection />
                <div className="contact__info">
                    <h2> 100 %</h2>
                    <div className="contact__info__right">
                        <p>
                            <b>de vos dons bénéficient directement aux projets</b>, le Groupe Nutriset
                            prenant en charge l’ensemble des frais de fonctionnement du Fonds de Dotation.
                        </p>
                        <p><b>100 % des dons sont éligibles aux déductions fiscales:</b></p>
                        <ul>
                            <li> - À hauteur de 60 % pour les entreprises payant l’impôt sur les sociétés.</li>
                            <li>- À hauteur de 66 % pour les particuliers, à déduire de leur impôt sur le revenu.</li>
                        </ul>
                    </div>
                </div>
                <ContactForm/>
            </div>
        </section>
    );
}
