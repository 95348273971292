import "./styles.css";
import {IMG_URL} from "../../utils/api/defaultApi";
import {useState} from "react";
import {LandingImage} from "./landingImage";

const NavHeader = ({setShow, show}) => {

    function handleClick(e) {
        e.preventDefault();
        setShow(!show);
    }

    return (
        <div className="nav__header">
            <button onClick={handleClick}>
                <img src='http://localhost:3000/images/icons/menu-sm-icon.png' alt="menu_icon" />
            </button>
            <a href="/">
                <img src={IMG_URL + "logo-fdd-174.webp"} alt="logo-fonds-de-dotation" />
            </a>
        </div>
    );
}

const NavContent = ({show}) => {
    return (show  && window.innerWidth < 1024) || window.innerWidth >= 1024 ?
        <div className="nav__content">
            <a className="nav__link" href="#about"><p>Qui sommes-nous</p></a>
            <a className="nav__link" href="#cognition"><p>Cognition et nutrition</p></a>
            <a className="nav__link" href="#project"><p>Projets</p></a>
            <a className="nav__link" href="#partner"><p>Partenaires</p></a>
            <a className="nav__link" href="#contact"><p>Contactez-nous</p></a>
            <a className="nav__link" href="#en"><p>ENG</p></a>
        </div> : null;
}

export const Header = () => {
    const [show, setShow] = useState(false);

    return (
        <header>
            <nav>
                <NavHeader setShow={setShow} show={show} />
                <NavContent show={show} />
            </nav>
            <LandingImage/>
        </header>
    );
}