import "./styles.css"
import {useNavigate} from "react-router-dom";

export const ButtonFeatures = ({type, url, value}) => {
    const navigate = useNavigate();
    return (
        <button
            type={type}
            className="button buttonCustom"
            onClicl={()=>navigate(url)}
        >
            {value}
        </button>
    );
}
