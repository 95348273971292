import "./styles.css"

import {Title} from "../../compenents/Commons";

import {IMG_URL} from "../../utils/api/defaultApi";


const ProjectList = [
    {
        id: 0,
        title: "EXPLORER - Acquisition du goût",
        text: "Comment se construisent les préférences alimentaires chez nos enfants ? Le goût est-il un sens inné ou est-il possible de le développer, voire même de l’éduquer ? "
    },
    {
        id: 1,
        title: "EXPLORER - Acquisition du goût",
        text: "Comment se construisent les préférences alimentaires chez nos enfants ? Le goût est-il un sens inné ou est-il possible de le développer, voire même de l’éduquer ? "
    },
    {
        id: 2,
        title: "EXPLORER - Acquisition du goût",
        text: "Comment se construisent les préférences alimentaires chez nos enfants ? Le goût est-il un sens inné ou est-il possible de le développer, voire même de l’éduquer ? "
    },
];

const RenderSeparator = ({key, max}) => {
    console.log(key, max);
    return  <hr className="project__separator" />;
}

const ProjectElement = ({value}) => {
    return (
        <div className="container">
            <div>
                <h2 className="project__list__title">{value.title}</h2>
                <p>{value.text}</p>
            </div>
            <p>
                <img src={IMG_URL + "puce-genese.svg"} className="puce__genese" alt={"puce-genese"+value.id}/>
            </p>
        </div>
    );
}

const ProjetAccordion = () => {
    const length = ProjectList.length;
    return (
            <div className="project__list">
                {ProjectList.map(element => {

                    return (
                        <div key={element.id}>
                            <ProjectElement value={element} />
                            <RenderSeparator max={length} key={element.id}/>
                        </div>
                    );
                })}
            </div>
    );
}

export const Project = () => {
    return (
        <section className="project">
            <div>
                <p className="project__div__circle">
                    <img src={IMG_URL+"cercle-vert-coupe.webp"} alt="cercle-vert-coupe" />
                </p>
                <div className="project__div__title">
                    <Title id="project"  value="Les projets du Fonds de dotation Goupe Nutriset" />
                </div>
                <ProjetAccordion />
            </div>
        </section>
    );
}